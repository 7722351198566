import React, { createContext, useState, useEffect } from "react";

// Create the context
export const ContactModalContext = createContext();

// Create a provider component
export const ContactModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (isOpen) {
        setIsOpen(false); // Close the modal when back button is pressed
      }
    };

    // Listen for the popstate event
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isOpen]);

  const handleButtonClick = () => {
    setIsOpen((prev) => {
      if (!prev) {
        // Push a new state to the browser history when opening the modal
        window.history.pushState(null, null, window.location.href);
      }
      return !prev;
    });
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsOpen(false); // Close the modal
    }
  };

  return (
    <ContactModalContext.Provider
      value={{ isOpen, handleButtonClick, handleOverlayClick }}
    >
      {children}
    </ContactModalContext.Provider>
  );
};
