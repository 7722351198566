import React, { lazy, Suspense } from "react";
const CookieConsent = lazy(() => import("react-cookie-consent"));

const CookieBanner = () => {
  return (
    <Suspense fallback={null}>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myWebsiteCookieConsent"
        style={{ background: "#2B373B", zIndex: 1001 }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance your browsing experience.{" "}
        <a
          href="/privacy-policy"
          style={{ color: "#fff", textDecoration: "underline" }}
        >
          View our Privacy Policy.
        </a>
      </CookieConsent>
    </Suspense>
  );
};

export default CookieBanner;
