export default {
  "002cd966": [() => import(/* webpackChunkName: "002cd966" */ "@site/src/pages/privacy-policy.js"), "@site/src/pages/privacy-policy.js", require.resolveWeak("@site/src/pages/privacy-policy.js")],
  "0058b4c6": [() => import(/* webpackChunkName: "0058b4c6" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-175.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-175.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-175.json")],
  "0150046e": [() => import(/* webpackChunkName: "0150046e" */ "@site/blog/2024-10-22/langchain-conversational-ai.mdx"), "@site/blog/2024-10-22/langchain-conversational-ai.mdx", require.resolveWeak("@site/blog/2024-10-22/langchain-conversational-ai.mdx")],
  "01a85c17": [() => import(/* webpackChunkName: "01a85c17" */ "@theme/BlogTagsListPage"), "@theme/BlogTagsListPage", require.resolveWeak("@theme/BlogTagsListPage")],
  "06018e5b": [() => import(/* webpackChunkName: "06018e5b" */ "@site/blog/2024-12-05/vuejs-chatbot-development.mdx"), "@site/blog/2024-12-05/vuejs-chatbot-development.mdx", require.resolveWeak("@site/blog/2024-12-05/vuejs-chatbot-development.mdx")],
  "06dc9858": [() => import(/* webpackChunkName: "06dc9858" */ "@site/docs/LLM/deploying-LLM-docker.mdx"), "@site/docs/LLM/deploying-LLM-docker.mdx", require.resolveWeak("@site/docs/LLM/deploying-LLM-docker.mdx")],
  "0bd648cf": [() => import(/* webpackChunkName: "0bd648cf" */ "@site/blog/2024-10-17/serverless-computing.mdx"), "@site/blog/2024-10-17/serverless-computing.mdx", require.resolveWeak("@site/blog/2024-10-17/serverless-computing.mdx")],
  "12f17a61": [() => import(/* webpackChunkName: "12f17a61" */ "@site/blog/2024-10-18/embracing-artificial-intelligence-in-hospitality.mdx"), "@site/blog/2024-10-18/embracing-artificial-intelligence-in-hospitality.mdx", require.resolveWeak("@site/blog/2024-10-18/embracing-artificial-intelligence-in-hospitality.mdx")],
  "13ab27aa": [() => import(/* webpackChunkName: "13ab27aa" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-aws-70f.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-aws-70f.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-aws-70f.json")],
  "13d8c32a": [() => import(/* webpackChunkName: "13d8c32a" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-microservices-27e.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-microservices-27e.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-microservices-27e.json")],
  "17896441": [() => import(/* webpackChunkName: "17896441" */ "@theme/DocItem"), "@theme/DocItem", require.resolveWeak("@theme/DocItem")],
  "1a4e3797": [() => import(/* webpackChunkName: "1a4e3797" */ "@theme/SearchPage"), "@theme/SearchPage", require.resolveWeak("@theme/SearchPage")],
  "1d231ba6": [() => import(/* webpackChunkName: "1d231ba6" */ "@site/blog/2024-10-17/essential-devops-tools.mdx?truncated=true"), "@site/blog/2024-10-17/essential-devops-tools.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-17/essential-devops-tools.mdx?truncated=true")],
  "1d6c7131": [() => import(/* webpackChunkName: "1d6c7131" */ "@site/blog/2024-11-13/websockets-vs-SSE.mdx?truncated=true"), "@site/blog/2024-11-13/websockets-vs-SSE.mdx?truncated=true", require.resolveWeak("@site/blog/2024-11-13/websockets-vs-SSE.mdx?truncated=true")],
  "1f391b9e": [() => import(/* webpackChunkName: "1f391b9e" */ "@theme/MDXPage"), "@theme/MDXPage", require.resolveWeak("@theme/MDXPage")],
  "1ff83ef0": [() => import(/* webpackChunkName: "1ff83ef0" */ "@site/blog/2024-10-22/langchain-conversational-ai.mdx?truncated=true"), "@site/blog/2024-10-22/langchain-conversational-ai.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-22/langchain-conversational-ai.mdx?truncated=true")],
  "25d1f3de": [() => import(/* webpackChunkName: "25d1f3de" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-kubernetes-bf9.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-kubernetes-bf9.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-kubernetes-bf9.json")],
  "292377b2": [() => import(/* webpackChunkName: "292377b2" */ "@site/docs/AWS/deploying-an-application-in-aws-lambda.mdx"), "@site/docs/AWS/deploying-an-application-in-aws-lambda.mdx", require.resolveWeak("@site/docs/AWS/deploying-an-application-in-aws-lambda.mdx")],
  "2dac08f1": [() => import(/* webpackChunkName: "2dac08f1" */ "@site/blog/2024-10-15/containerization-in-devops.mdx"), "@site/blog/2024-10-15/containerization-in-devops.mdx", require.resolveWeak("@site/blog/2024-10-15/containerization-in-devops.mdx")],
  "306d9a84": [() => import(/* webpackChunkName: "306d9a84" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-iac-c6d.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-iac-c6d.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-iac-c6d.json")],
  "311e635b": [() => import(/* webpackChunkName: "311e635b" */ "@site/blog/2024-11-18/understanding-langchain.mdx?truncated=true"), "@site/blog/2024-11-18/understanding-langchain.mdx?truncated=true", require.resolveWeak("@site/blog/2024-11-18/understanding-langchain.mdx?truncated=true")],
  "33fc5bb8": [() => import(/* webpackChunkName: "33fc5bb8" */ "@theme/Blog/Pages/BlogAuthorsPostsPage"), "@theme/Blog/Pages/BlogAuthorsPostsPage", require.resolveWeak("@theme/Blog/Pages/BlogAuthorsPostsPage")],
  "36994c47": [() => import(/* webpackChunkName: "36994c47" */ "@generated/docusaurus-plugin-content-blog/default/__plugin.json"), "@generated/docusaurus-plugin-content-blog/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/__plugin.json")],
  "3720c009": [() => import(/* webpackChunkName: "3720c009" */ "@theme/DocTagsListPage"), "@theme/DocTagsListPage", require.resolveWeak("@theme/DocTagsListPage")],
  "393be207": [() => import(/* webpackChunkName: "393be207" */ "@site/src/pages/markdown-page.md"), "@site/src/pages/markdown-page.md", require.resolveWeak("@site/src/pages/markdown-page.md")],
  "3a2db09e": [() => import(/* webpackChunkName: "3a2db09e" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-df9.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-df9.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-df9.json")],
  "3bbd5350": [() => import(/* webpackChunkName: "3bbd5350" */ "@site/docs/Kubernetes/kubernetes-installation-and-setup.mdx"), "@site/docs/Kubernetes/kubernetes-installation-and-setup.mdx", require.resolveWeak("@site/docs/Kubernetes/kubernetes-installation-and-setup.mdx")],
  "44ee8096": [() => import(/* webpackChunkName: "44ee8096" */ "@site/blog/2024-10-21/complete-chatbots-guide.mdx"), "@site/blog/2024-10-21/complete-chatbots-guide.mdx", require.resolveWeak("@site/blog/2024-10-21/complete-chatbots-guide.mdx")],
  "468df252": [() => import(/* webpackChunkName: "468df252" */ "@site/blog/2024-11-18/understanding-langchain.mdx"), "@site/blog/2024-11-18/understanding-langchain.mdx", require.resolveWeak("@site/blog/2024-11-18/understanding-langchain.mdx")],
  "4e2fcc91": [() => import(/* webpackChunkName: "4e2fcc91" */ "@site/blog/2024-11-09/microservices-in-ecomm.mdx"), "@site/blog/2024-11-09/microservices-in-ecomm.mdx", require.resolveWeak("@site/blog/2024-11-09/microservices-in-ecomm.mdx")],
  "53580945": [() => import(/* webpackChunkName: "53580945" */ "@site/docs/Terraform/terraform-installation.mdx"), "@site/docs/Terraform/terraform-installation.mdx", require.resolveWeak("@site/docs/Terraform/terraform-installation.mdx")],
  "5606cc91": [() => import(/* webpackChunkName: "5606cc91" */ "@site/blog/2024-10-16/ansible-benefits.mdx?truncated=true"), "@site/blog/2024-10-16/ansible-benefits.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-16/ansible-benefits.mdx?truncated=true")],
  "59c0e013": [() => import(/* webpackChunkName: "59c0e013" */ "@site/blog/2024-10-17/microservice-architecture-in-2024.mdx"), "@site/blog/2024-10-17/microservice-architecture-in-2024.mdx", require.resolveWeak("@site/blog/2024-10-17/microservice-architecture-in-2024.mdx")],
  "5e95c892": [() => import(/* webpackChunkName: "5e95c892" */ "@theme/DocsRoot"), "@theme/DocsRoot", require.resolveWeak("@theme/DocsRoot")],
  "5e9f5e1a": [() => import(/* webpackChunkName: "5e9f5e1a" */ "@generated/docusaurus.config"), "@generated/docusaurus.config", require.resolveWeak("@generated/docusaurus.config")],
  "5f462cfb": [() => import(/* webpackChunkName: "5f462cfb" */ "@site/blog/2024-12-05/vuejs-chatbot-development.mdx?truncated=true"), "@site/blog/2024-12-05/vuejs-chatbot-development.mdx?truncated=true", require.resolveWeak("@site/blog/2024-12-05/vuejs-chatbot-development.mdx?truncated=true")],
  "60b70afc": [() => import(/* webpackChunkName: "60b70afc" */ "@site/blog/2024-10-22/best-practices-for-ci-cd-pipelines.mdx"), "@site/blog/2024-10-22/best-practices-for-ci-cd-pipelines.mdx", require.resolveWeak("@site/blog/2024-10-22/best-practices-for-ci-cd-pipelines.mdx")],
  "61ee57b9": [() => import(/* webpackChunkName: "61ee57b9" */ "@site/blog/2024-10-16/ansible-benefits.mdx"), "@site/blog/2024-10-16/ansible-benefits.mdx", require.resolveWeak("@site/blog/2024-10-16/ansible-benefits.mdx")],
  "621db11d": [() => import(/* webpackChunkName: "621db11d" */ "@theme/Blog/Pages/BlogAuthorsListPage"), "@theme/Blog/Pages/BlogAuthorsListPage", require.resolveWeak("@theme/Blog/Pages/BlogAuthorsListPage")],
  "65a6d713": [() => import(/* webpackChunkName: "65a6d713" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-ai-9a5.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-ai-9a5.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-ai-9a5.json")],
  "6860ba4d": [() => import(/* webpackChunkName: "6860ba4d" */ "@site/blog/2024-10-23/understanding-machine-learning.mdx"), "@site/blog/2024-10-23/understanding-machine-learning.mdx", require.resolveWeak("@site/blog/2024-10-23/understanding-machine-learning.mdx")],
  "68645db5": [() => import(/* webpackChunkName: "68645db5" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-chatbot-c33.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-chatbot-c33.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-chatbot-c33.json")],
  "6875c492": [() => import(/* webpackChunkName: "6875c492" */ "@theme/BlogTagsPostsPage"), "@theme/BlogTagsPostsPage", require.resolveWeak("@theme/BlogTagsPostsPage")],
  "6a09de09": [() => import(/* webpackChunkName: "6a09de09" */ "@site/blog/2024-10-17/serverless-computing.mdx?truncated=true"), "@site/blog/2024-10-17/serverless-computing.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-17/serverless-computing.mdx?truncated=true")],
  "6f2923ca": [() => import(/* webpackChunkName: "6f2923ca" */ "@site/blog/2024-10-22/infrastructure-as-code-for-gitops.mdx"), "@site/blog/2024-10-22/infrastructure-as-code-for-gitops.mdx", require.resolveWeak("@site/blog/2024-10-22/infrastructure-as-code-for-gitops.mdx")],
  "814f3328": [() => import(/* webpackChunkName: "814f3328" */ "~blog/default/blog-post-list-prop-default.json"), "~blog/default/blog-post-list-prop-default.json", require.resolveWeak("~blog/default/blog-post-list-prop-default.json")],
  "8213c958": [() => import(/* webpackChunkName: "8213c958" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-langchain-09b.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-langchain-09b.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-langchain-09b.json")],
  "82cb2d07": [() => import(/* webpackChunkName: "82cb2d07" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-ml-73e.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-ml-73e.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-ml-73e.json")],
  "83d4f970": [() => import(/* webpackChunkName: "83d4f970" */ "@site/blog/2024-10-18/intelligent-chatbot.mdx"), "@site/blog/2024-10-18/intelligent-chatbot.mdx", require.resolveWeak("@site/blog/2024-10-18/intelligent-chatbot.mdx")],
  "83e9e333": [() => import(/* webpackChunkName: "83e9e333" */ "@site/src/pages/contact.js"), "@site/src/pages/contact.js", require.resolveWeak("@site/src/pages/contact.js")],
  "898514b1": [() => import(/* webpackChunkName: "898514b1" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-e58.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-e58.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-e58.json")],
  "8be8ac58": [() => import(/* webpackChunkName: "8be8ac58" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ai-848.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ai-848.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ai-848.json")],
  "8da4bca3": [() => import(/* webpackChunkName: "8da4bca3" */ "@site/blog/2024-10-21/complete-chatbots-guide.mdx?truncated=true"), "@site/blog/2024-10-21/complete-chatbots-guide.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-21/complete-chatbots-guide.mdx?truncated=true")],
  "8ea09047": [() => import(/* webpackChunkName: "8ea09047" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-page-2-433.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-page-2-433.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-page-2-433.json")],
  "8f6c706a": [() => import(/* webpackChunkName: "8f6c706a" */ "@site/docs/Terraform/deploy-app-on-ec2-using-terraform.mdx"), "@site/docs/Terraform/deploy-app-on-ec2-using-terraform.mdx", require.resolveWeak("@site/docs/Terraform/deploy-app-on-ec2-using-terraform.mdx")],
  "8f752917": [() => import(/* webpackChunkName: "8f752917" */ "@site/blog/2024-10-22/best-practices-for-ci-cd-pipelines.mdx?truncated=true"), "@site/blog/2024-10-22/best-practices-for-ci-cd-pipelines.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-22/best-practices-for-ci-cd-pipelines.mdx?truncated=true")],
  "91a39dd0": [() => import(/* webpackChunkName: "91a39dd0" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-docker-eea.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-docker-eea.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-docker-eea.json")],
  "9d5c7492": [() => import(/* webpackChunkName: "9d5c7492" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-k-8-s-136.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-k-8-s-136.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-k-8-s-136.json")],
  "9e4087bc": [() => import(/* webpackChunkName: "9e4087bc" */ "@theme/BlogArchivePage"), "@theme/BlogArchivePage", require.resolveWeak("@theme/BlogArchivePage")],
  "a29455a2": [() => import(/* webpackChunkName: "a29455a2" */ "@site/blog/2024-10-23/understanding-machine-learning.mdx?truncated=true"), "@site/blog/2024-10-23/understanding-machine-learning.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-23/understanding-machine-learning.mdx?truncated=true")],
  "a4fc2ca6": [() => import(/* webpackChunkName: "a4fc2ca6" */ "@site/blog/2024-10-22/infrastructure-as-code-for-gitops.mdx?truncated=true"), "@site/blog/2024-10-22/infrastructure-as-code-for-gitops.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-22/infrastructure-as-code-for-gitops.mdx?truncated=true")],
  "a6983291": [() => import(/* webpackChunkName: "a6983291" */ "@site/blog/2024-10-16/llm-docker.mdx"), "@site/blog/2024-10-16/llm-docker.mdx", require.resolveWeak("@site/blog/2024-10-16/llm-docker.mdx")],
  "a6aa9e1f": [() => import(/* webpackChunkName: "a6aa9e1f" */ "@theme/BlogListPage"), "@theme/BlogListPage", require.resolveWeak("@theme/BlogListPage")],
  "a7456010": [() => import(/* webpackChunkName: "a7456010" */ "@generated/docusaurus-plugin-content-pages/default/__plugin.json"), "@generated/docusaurus-plugin-content-pages/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-pages/default/__plugin.json")],
  "a7bd4aaa": [() => import(/* webpackChunkName: "a7bd4aaa" */ "@theme/DocVersionRoot"), "@theme/DocVersionRoot", require.resolveWeak("@theme/DocVersionRoot")],
  "a7ebd951": [() => import(/* webpackChunkName: "a7ebd951" */ "@site/blog/2024-10-15/containerization-in-devops.mdx?truncated=true"), "@site/blog/2024-10-15/containerization-in-devops.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-15/containerization-in-devops.mdx?truncated=true")],
  "a8d03e97": [() => import(/* webpackChunkName: "a8d03e97" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ia-c-876.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ia-c-876.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-ia-c-876.json")],
  "a94703ab": [() => import(/* webpackChunkName: "a94703ab" */ "@theme/DocRoot"), "@theme/DocRoot", require.resolveWeak("@theme/DocRoot")],
  "aba21aa0": [() => import(/* webpackChunkName: "aba21aa0" */ "@generated/docusaurus-plugin-content-docs/default/__plugin.json"), "@generated/docusaurus-plugin-content-docs/default/__plugin.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/__plugin.json")],
  "acecf23e": [() => import(/* webpackChunkName: "acecf23e" */ "~blog/default/blogMetadata-default.json"), "~blog/default/blogMetadata-default.json", require.resolveWeak("~blog/default/blogMetadata-default.json")],
  "adb00d9a": [() => import(/* webpackChunkName: "adb00d9a" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-llm-eb0.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-llm-eb0.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-llm-eb0.json")],
  "b9a0a472": [() => import(/* webpackChunkName: "b9a0a472" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-aws-754.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-aws-754.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-aws-754.json")],
  "bd92b13f": [() => import(/* webpackChunkName: "bd92b13f" */ "@site/blog/2024-10-18/intelligent-chatbot.mdx?truncated=true"), "@site/blog/2024-10-18/intelligent-chatbot.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-18/intelligent-chatbot.mdx?truncated=true")],
  "bf729baf": [() => import(/* webpackChunkName: "bf729baf" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-authors-suraj-6c0.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-authors-suraj-6c0.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-authors-suraj-6c0.json")],
  "c141421f": [() => import(/* webpackChunkName: "c141421f" */ "@generated/docusaurus-theme-search-algolia/default/__plugin.json"), "@generated/docusaurus-theme-search-algolia/default/__plugin.json", require.resolveWeak("@generated/docusaurus-theme-search-algolia/default/__plugin.json")],
  "c15d9823": [() => import(/* webpackChunkName: "c15d9823" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-bd9.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-bd9.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-bd9.json")],
  "c3a7ccb3": [() => import(/* webpackChunkName: "c3a7ccb3" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-cloud-4d2.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-cloud-4d2.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-cloud-4d2.json")],
  "c4f5d8e4": [() => import(/* webpackChunkName: "c4f5d8e4" */ "@site/src/pages/index.js"), "@site/src/pages/index.js", require.resolveWeak("@site/src/pages/index.js")],
  "c7376cc8": [() => import(/* webpackChunkName: "c7376cc8" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-authors-suraj-authors-2-1f4.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-authors-suraj-authors-2-1f4.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-authors-suraj-authors-2-1f4.json")],
  "ccc49370": [() => import(/* webpackChunkName: "ccc49370" */ "@theme/BlogPostPage"), "@theme/BlogPostPage", require.resolveWeak("@theme/BlogPostPage")],
  "cf25ad76": [() => import(/* webpackChunkName: "cf25ad76" */ "@site/blog/2024-10-21/openai-llm-in-chatbots.mdx?truncated=true"), "@site/blog/2024-10-21/openai-llm-in-chatbots.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-21/openai-llm-in-chatbots.mdx?truncated=true")],
  "cfe9877c": [() => import(/* webpackChunkName: "cfe9877c" */ "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-dev-ops-1ee.json"), "@generated/docusaurus-plugin-content-docs/default/p/docs-tags-dev-ops-1ee.json", require.resolveWeak("@generated/docusaurus-plugin-content-docs/default/p/docs-tags-dev-ops-1ee.json")],
  "d414a8e7": [() => import(/* webpackChunkName: "d414a8e7" */ "@site/blog/2024-10-16/llm-docker.mdx?truncated=true"), "@site/blog/2024-10-16/llm-docker.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-16/llm-docker.mdx?truncated=true")],
  "d4dfe46c": [() => import(/* webpackChunkName: "d4dfe46c" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-llm-398.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-llm-398.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-llm-398.json")],
  "d71a5813": [() => import(/* webpackChunkName: "d71a5813" */ "@site/src/pages/terms-of-service.js"), "@site/src/pages/terms-of-service.js", require.resolveWeak("@site/src/pages/terms-of-service.js")],
  "db6c6bb8": [() => import(/* webpackChunkName: "db6c6bb8" */ "@site/blog/2024-10-17/microservice-architecture-in-2024.mdx?truncated=true"), "@site/blog/2024-10-17/microservice-architecture-in-2024.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-17/microservice-architecture-in-2024.mdx?truncated=true")],
  "dc5849fd": [() => import(/* webpackChunkName: "dc5849fd" */ "@site/blog/2024-11-09/microservices-in-ecomm.mdx?truncated=true"), "@site/blog/2024-11-09/microservices-in-ecomm.mdx?truncated=true", require.resolveWeak("@site/blog/2024-11-09/microservices-in-ecomm.mdx?truncated=true")],
  "df203c0f": [() => import(/* webpackChunkName: "df203c0f" */ "@theme/DocTagDocListPage"), "@theme/DocTagDocListPage", require.resolveWeak("@theme/DocTagDocListPage")],
  "e36f65f0": [() => import(/* webpackChunkName: "e36f65f0" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-devops-895.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-devops-895.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-devops-895.json")],
  "e61aebb4": [() => import(/* webpackChunkName: "e61aebb4" */ "@site/blog/2024-10-21/openai-llm-in-chatbots.mdx"), "@site/blog/2024-10-21/openai-llm-in-chatbots.mdx", require.resolveWeak("@site/blog/2024-10-21/openai-llm-in-chatbots.mdx")],
  "eba53a42": [() => import(/* webpackChunkName: "eba53a42" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-vue-c9d.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-tags-vue-c9d.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-tags-vue-c9d.json")],
  "ed2af8ef": [() => import(/* webpackChunkName: "ed2af8ef" */ "@site/blog/2024-10-17/essential-devops-tools.mdx"), "@site/blog/2024-10-17/essential-devops-tools.mdx", require.resolveWeak("@site/blog/2024-10-17/essential-devops-tools.mdx")],
  "ef8b811a": [() => import(/* webpackChunkName: "ef8b811a" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-authors-790.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-authors-790.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-authors-790.json")],
  "f3f125e9": [() => import(/* webpackChunkName: "f3f125e9" */ "@site/blog/2024-10-18/embracing-artificial-intelligence-in-hospitality.mdx?truncated=true"), "@site/blog/2024-10-18/embracing-artificial-intelligence-in-hospitality.mdx?truncated=true", require.resolveWeak("@site/blog/2024-10-18/embracing-artificial-intelligence-in-hospitality.mdx?truncated=true")],
  "f81c1134": [() => import(/* webpackChunkName: "f81c1134" */ "@generated/docusaurus-plugin-content-blog/default/p/blog-archive-f05.json"), "@generated/docusaurus-plugin-content-blog/default/p/blog-archive-f05.json", require.resolveWeak("@generated/docusaurus-plugin-content-blog/default/p/blog-archive-f05.json")],
  "f8ba4e9b": [() => import(/* webpackChunkName: "f8ba4e9b" */ "@site/blog/2024-11-13/websockets-vs-SSE.mdx"), "@site/blog/2024-11-13/websockets-vs-SSE.mdx", require.resolveWeak("@site/blog/2024-11-13/websockets-vs-SSE.mdx")],};
