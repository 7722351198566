// src/theme/Layout.js
import React from "react";
import Layout from "@theme-original/Layout";
import { ContactModalProvider } from "../context/ContactModalContext"; // Import the provider
import ScrollToTop from "@site/src/components/ScrollToTop";
import CookieBanner from "@site/src/components/CookieBanner";

export default function CustomLayout(props) {
  return (
    <ContactModalProvider>
      <Layout {...props} />
      <ScrollToTop />
      <CookieBanner />
    </ContactModalProvider>
  );
}
